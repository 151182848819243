<template>
  <div>
    <div class="user-top">
      <a-button type="primary" @click="showAddModal">
        添加账户
      </a-button>
    </div>
    <a-table
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      :loading="dataLoading"
      :pagination="{
        hideOnSinglePage: true,
        current: page.currentPage,
        total: page.total,
      }"
      :columns="columns"
      :data-source="list"
      :bordered="true"
      @change="listChange"
    >
      <template slot="operation" slot-scope="index, record">
        <div class="control-box">
          <a-button type="primary" size="small" @click="editUser(record)">修改</a-button>
          <a-popconfirm
            title="确认删除该员工?"
            ok-text="是"
            cancel-text="否"
            placement="left"
            @confirm="deleteUser(record)"
          >
            <a-button type="danger" size="small">删除</a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-table>

    <a-modal
      v-model="showUser"
      :title="isEdit ? '修改信息' : '添加员工'"
      :confirmLoading="addIng"
      :cancelText="'取消'"
      :okText="'提交'"
      @ok="addUser"
    >
      <a-form-model ref="addForm" :model="addForm" :rules="rules">
        <a-form-model-item
          has-feedback
          label="姓名"
          prop="name"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-input v-model="addForm.name" type="text" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item
          has-feedback
          label="手机号"
          prop="phone"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-input v-model.number="addForm.phone" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item
          has-feedback
          label="密码"
          prop="password"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-input v-model="addForm.password" autocomplete="off" type="password"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
let columns = [
  {
    title: "ID",
    dataIndex: "user_id",
    key: "user_id",
  },
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "手机号",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: "128px",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  data() {
    return {
      isEdit: false,
      addIng: false,
      showUser: false,
      dataLoading: false,
      columns: columns,
      searchInfo: "",
      page: {
        currentPage: 1,
        total: 0,
      },
      addForm: {
        name: "",
        phone: "",
        password: "",
      },
      list: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 8,
            max: 16,
            message: "请输入8位密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    console.log("jinlail;e");
    this.getCmsUser();
  },
  methods: {
    getCmsUser() {
      console.log(12312312);
      let t = this;
      t.$get({
        port: "a",
        url: "userList",
        data: {
          searchInfo: t.searchInfo,
          page: t.page.currentPage,
        },
      })
        .then((res) => {
          let { code, msg, data } = res;
          if (code == 0) {
            t.page.total = data.total;
            t.list = data.data;
          }
        })
        .catch((err) => {});
    },
    deleteUser(item) {
      let t = this;
      t.$get({
        port: "a",
        url: "userDel",
        data: {
          user_id: item.user_id,
        },
      }).then((res) => {
        let { code, msg, data } = res;
        if (code == 0) {
          t.$message.success("删除成功", 1.5);
          t.getCmsUser();
        } else {
          t.$message.error(msg, 1.5);
        }
      });
    },
    listChange(e) {
      this.page.currentPage = e.current;
      this.getCmsUser();
    },
    showAddModal() {
      this.isEdit = false
      this.showUser = true
    },
    addUser() {
      let t = this;
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          t.addIng = true
          let url = t.isEdit ? 'userEdit' : 'userAdd'
          let obj = {...t.addForm}
          t.$post({
            port: "a",
            url: url,
            data: t.addForm,
          })
            .then((res) => {
              t.addIng = false;
              let { code, data, msg } = res;
              t.showUser = false;
              if (code == 0) {
                t.$message.success(msg, 1.5);
                t.getCmsUser();
                this.$refs.addForm.resetFields();
              } else {
                t.$message.error(msg, 1.5);
              }
            })
            .catch((err) => {
              t.addIng = false;
            });
        } else {
          return false;
        }
      });
    },
    editUser(item) {
      let t = this
      t.addForm = {
        name: item.name,
        phone: item.phone,
        user_id: item.user_id,
        password: "",
      }
      t.isEdit = true
      t.showUser = true
    }
  },
};
</script>
<style lang="less">
.user-top {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}
</style>
